
<template>
  <div>
    <van-search readonly class="my_VanSearch" @focus="showTimeFun" :right-icon="rightIcon" :left-icon="leftIcon"
      v-model="inputValue" :placeholder="placeholder">
      <!-- <template #right-icon>
        <van-icon name="arrow-down" />
      </template> -->
    </van-search>
    <van-popup v-model="showSelModel" position="bottom" :style="{ 'height': height }">
      <van-picker title="标题" show-toolbar :columns="data" :value-key="valueKey" @confirm="onConfirm" @cancel="onCancel"
        @change="onChange" />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    // 选项对象中，选项文字对应的键名
    valueKey: {
      type: String,
      default: 'name'
    },
    // 数据列表
    data: {
      type: Array,
      required: true
    },
    // 绑定数据
    value: {
      required: true
    },
    // 弹出框高度
    height: {
      type: String,
      default: '40%'
    },
    // 站字符
    placeholder: {
      type: String,
      default: ''
    },
    // 左侧icon
    leftIcon: {
      type: String,
      default: ''
    },
    // 右侧icon
    rightIcon: {
      type: String,
      default: 'arrow-down'
    },
  },
  data() {
    return {
      showSelModel: false, // 弹窗显示
      
    }
  },
  created() {
    console.log(this.value,this.valueKey)
    // this.inputValue = this.value[this.valueKey];
  },
  computed:{
    inputValue:function(){
      return this.value[this.valueKey]
    }
  },
  methods: {
    onConfirm(value, index) {
      this.$emit('change', value);
      this.showSelModel = false;
    },
    onChange(picker, value, index) {
      // console.log(value)
      // console.log(`onChange当前值：${value}, 当前索引：${index},picker：${picker}`);
    },
    onCancel() {
      console.log('取消');
    },
    showTimeFun() {
      this.showSelModel = true
    }
  }
}
</script>

<style lang="scss" scoped>
.my_VanSearch{
  position: relative;
  ::v-deep.van-field__right-icon{
    position: absolute;
    top: 0px;
    right: 0px;
    pointer-events: none;
  }
}
</style>