<template>
  <div class="content_box theme_bgText qualit_box">
    <div class="head_site_choice">
      <div class="title">站点查询：</div>
      <my-picker style="flex:1;"
                 ref="myPicker"
                 :data="siteList"
                 v-model="siteActive"
                 valueKey="position_name"
                 @change="e=>{siteActive = e, selTypeFun(e)}">
      </my-picker>
    </div>
    <div class="main-content">
      <van-row class="main-panel" :gutter="30">
        <van-col class="main-panel-item1" span="24">
          <div class="AQIPart">
            <p class="update_time">更新时间：{{updateTime}}</p>
            <div class="AQI"  ref="ecDiv">
              <canvas ref="ecAQL" v-if="showCanvas" :width='AQIPartSize.width' :height='AQIPartSize.height'></canvas>
              <ul class="AQIUNIT">
                <li class="text1">
                  <span>0</span>
                  <p>健康</p>
                </li>
                <li class="text2">
                  <span>50</span>
                  <p>优</p>
                </li>
                <li class="text3">
                  <span>100</span>
                  <p>良</p>
                </li>
                <li class="text4">
                  <span>150</span>
                  <p>轻度</p>
                </li>
                <li class="text5">
                  <span>200</span>
                  <p>中度</p>
                </li>
                <li class="text6">
                  <span>300</span>
                  <p>重度</p>
                </li>
                <li class="text7">
                  <span>500</span>
                  <p>严重</p>
                </li>
              </ul>
              <div class="AQIText">
                <span class="font40 aqinum">{{siteActive.aqi}}</span>
                <p class="aqitxt">{{siteActive.quality}}</p>
                <span>AQI</span>
              </div>
            </div>
          </div>
        </van-col>
        <van-col v-for="item in panelList"
                 :key="item.label"
                 class="main-panel-item2"
                 span="12">
          <div class="panel-name">
            <img :src="`${require('@/assets/newImg/' + item.img)}`"
                 alt="">
            <div>
              <div>{{ item.label }}</div>
              <span>{{ item.name }}</span>
            </div>
          </div>
          <p class="panel-data">
            <span>{{ item.data }}{{ item.unit }}</span>
            <!-- <label for="" v-html="GetLevel(item.data).html"></label> -->
          </p>
          <div class="panel-Data-graph">
            <div :style="AQLdataBgFun(item.data)"
                 class="graph"></div>
          </div>
        </van-col>
        <!-- <van-col class="main-panel-item2" span="12"></van-col>
        <van-col class="main-panel-item2" span="12">4</van-col>
        <van-col class="main-panel-item2" span="12">5</van-col>
        <van-col class="main-panel-item2" span="12">6</van-col>
        <van-col class="main-panel-item2" span="12">7</van-col> -->
      </van-row>
    </div>
  </div>
</template>

<script>
import myPicker from '@/components/myPicker'
import { drawAQL } from "./airQuality.js"
import { GetAqiData } from "./Api.js"
export default {
  components: {
    myPicker
  },
  name: "airQuality",
  data() {
    return {
      siteList: [],
      AQIPartSize:{},
      showCanvas:true,
      siteActive: "",
      updateTime:"",
      panelList: {
        no2: { img: 'hj1.png', name: '二氧化氮', label: 'NO2', data: 0, unit: 'ug/m³' },
        o3: { img: 'hj2.png', name: '臭氧', label: 'O3', data: 0, unit: 'ug/m³' },
        co: { img: 'hj3.png', name: '一氧化碳', label: 'CO', data: 0, unit: 'mg/m³' },
        so2: { img: 'hj4.png', name: '二氧化硫', label: 'SO2', data: 0, unit: 'ug/m³' },
        pm2_5: { img: 'hj5.png', name: '', label: 'PM2.5', data: 0, unit: 'ug/m³' },
        pm10: { img: 'hj6.png', name: '', label: 'PM10', data: 0, unit: 'ug/m³' },
      },
    }
  },
  created() {
    this.getQuality(); // 查询数据
  },
  mounted(){
   this.getCanvasSize()
  },
  methods: {
    getCanvasSize(){
        var height = this.$refs.ecDiv.offsetHeight;
        var width = this.$refs.ecDiv.offsetWidth+20;
        this.AQIPartSize = {height:height-(height*0.1),width:width-(width*0.1)}
        var canvas = this.$refs.ecAQL;
        canvas.width = this.AQIPartSize.width;
        canvas.height = this.AQIPartSize.height;
        console.log(canvas.width,canvas.height)
    },
    // 选择的站点数据
    selTypeFun() {
      for (const key in this.panelList) {
        this.panelList[key].data = this.siteActive[key];
      }
      // this.getCanvasSize();
      // this.showCanvas = false;
      setTimeout(() => {
        //  this.showCanvas = true;
        this.getCanvasSize()
        this.drawEcAQL();
      }, 500);
      for (const key in this.siteActive) {
        console.log(key,this.siteActive[key])
      }
    },
    async getQuality() {
      const res = await GetAqiData();
      this.siteList = res.data;
      this.siteActive = this.siteList[0]; // 默认选中
      this.updateTime = res.data[0].time_point1+' '+res.data[0].time_point;
      this.selTypeFun()
    },
    drawEcAQL() {
      const dom = this.$refs.ecAQL;
      drawAQL(dom, this.siteActive);
    },

    AQLdataBgFun: val => {
      switch (true) {
        case (val > 0 && val < 30):
          return `width:${val}%; background-color:#00D135;`;
        case val > 30 && val < 50:
          return `width:${val}%; background-color:#FBFF44;`;
        case val > 50 && val < 70:
          return `width:${val}%; background-color:#FF6A16;`;
        case val > 70 && val < 90:
          return `width:${val}%; background-color:#FF1301;`;
        case val > 90 && val < 100:
          return `width:${val}%; background-color:#87007D;`;
        default:
          return `width:${val}%; background-color:#87007D;`;
      }
    },
    //判断空气质量
    GetLevel: (aqi) => {
      var aqicolorArr = ["#72B962", "#E1C556", "#E7855C", "#BF5056", "#A34874"];
      var LEVELS = ['优', '良', '轻度', '中度', '重度', '无'];
      var leve_index = aqi < 50 ? 0 : aqi < 100 ? 1 : aqi < 150 ? 2 : aqi < 200 ? 3 : 4;
      var level = LEVELS[leve_index],
        color = aqicolorArr[leve_index];
      console.log({ "level": level, "color": color, html: `<span style="color:${color};">${level}</span>` })
      return { "level": level, "color": color, html: `<span style="color:${color};">${level}</span>` };
    },

    AQLdataFun: val => {
      switch (true) {
        case (val > 0 && val < 30):
          return '<span style="color:#00D135;">健康</span>';
        case val > 30 && val < 50:
          return '<span style="color:#FBFF44;">轻度</span>';
        case val > 50 && val < 70:
          return '<span style="color:#FF6A16;">中度</span>';
        case val > 70 && val < 90:
          return '<span style="color:#FF1301;">重度</span>';
        case val > 90 && val < 100:
          return '<span style="color:#87007D;">严重</span>';
        default:
          return "";
      }
    }
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>
.qualit_box {
  padding: 8px 10px;
}

.head_site_choice {
  display: flex;
}

.main-content {
  // height: calc(100% - 50px);
  background-color: var(--become_voidBg);
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
}

.main-panel {
  height: 1rem;

  .main-panel-item1 {
    height: 50%;
  }

  .main-panel-item2 {
    padding: 8px;
    box-sizing: border-box;

    .panel-data {
      display: flex;
      justify-content: space-between;
      margin: 10px 0 5px;
    }

    .panel-Data-graph {
      width: 100%;
      overflow: hidden;
      background-color: rgba($color: #c9c9c9, $alpha: 0.5);
      border-radius: 10px;
      height: 7px;

      .graph {
        border-radius: 10px;
        height: 7px;
      }
    }

    .panel-name {
      display: flex;
      align-items: center;

      img {
        transform: translateX(-5px);
        width: 40px;
      }

      span {
        font-size: 12px;
      }
    }
  }
  .AQIPart {
    width: 100%;
    height: 320px;
    /*background:#284164;*/
    text-align: center;
  }
  .update_time{
    text-align: right;
    font-size: 12px;
    color: #fff;
    // color: #a9b2be;
    margin: 0;

  }
  .AQI {
    display: inline-block;
    margin: 0 auto;
    width: 300px;
    height: 100%;
    position: relative;
    // color: #a9b2be;
    color: #fff;
    font-size: 0.8em;
    p{
      margin: 0;
    }
  }
  .AQIUNIT li {
    position: absolute;
    display: inline-block;
    width: 50px;
    font-size: 1em;
  }
  .AQIUNIT .text1 {
    top: 260px;
    left: 30px;
  }
  .AQIUNIT .text2 {
    top: 175px;
    left: -10px;
  }
  .AQIUNIT .text3 {
    top: 70px;
    left: 5px;
  }
  .AQIUNIT .text4 {
    top: 10px;
    left: 125px;
  }
  .AQIUNIT .text5 {
    top: 70px;
    left: 245px;
  }
  .AQIUNIT .text6 {
    top: 175px;
    left: 260px;
  }
  .AQIUNIT .text7 {
    top: 260px;
    left: 220px;
  }
  .AQIText {
    width: 100px;
    display: inline-block;
    position: absolute;
    top: 140px;
    left: 100px;
    line-height: 1.6em;
    font-size: 1.5em;
  }

  .gauge-AQL-Ec {
    width: 100%;
    height: 100%;
  }

  .main-panel-item2 {
    height: calc((100% - 44%) / 3);
  }
}
</style>