
import request from "@/utils/request.js";



// GET /api/Wechat/GetAqiData 空气质量-查询环境数据

export function GetAqiData(params) {
  return request({
    url: "/api/Wechat/GetAqiData",
    method: "GET",
    params
  });
}