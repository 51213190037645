
import echarts from "@/utils/echarts";

export function drawAQL(dom,data){
  // var oC=document.getElementById('c1');
  console.log(dom,data)
  var oGC=dom.getContext('2d');
  var d = Number(data.aqi)
  // let echar = echarts.init(dom);
  // const option = {
  //   series: [
  //     {
  //       type: 'gauge',
  //       min: 0,
  //       max: 300,
  //       splitNumber: 6,
  //       radius: '60%',
  //       anchor: {
  //         show:false,
  //       },
  //       pointer: {
  //         show:false,
  //       },
  //       axisTick:{
  //         show:false,
  //       },
  //       progress: {
  //         show: true,
  //         width: 5,
  //         overlap: true,
  //         roundCap: true,
  //         itemStyle:{
  //            color: new echarts.graphic.LinearGradient(
  //             0, 1, 0, 0,//4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
  //             [
  //                 {offset: 1, color: '#830600'},
  //                 {offset: 0.90, color: '#87007D'},
  //                 {offset: 0.70, color: '#FF1301'},
  //                 {offset: 0.51, color: '#FF6A16'},
  //                 {offset: 0.17, color: '#FBFF44'},
  //                 {offset: 0.1, color: '#00D135'}
  //             ]                
  //           )
  //         }
  //       },
  //       axisLine: {
  //         roundCap: true,
  //         lineStyle: {
  //             width: 5,
  //             color:[[1,'rgba(255,255,255,0.3)']]
  //         }
  //       },
  //       splitLine:{
  //         show:false,
  //       },
  //       data: [
  //         {
  //           value: Number(data.aqi),
  //           name: data.quality,
  //           textStyle:{
  //             color:'#fff',
  //           },
  //             width: 6,
  //         }
  //       ],
  //       axisLabel: {
  //         color: '#c9c9c9',
  //         fontSize: 13,
  //         distance: -50,
  //         formatter: function (value) {
  //           console.log(value)
  //           if (value <= 0)  {
  //             return '  0\n健康';
  //           } else if (value >0 && value <= 50) {
  //             return '50\n优';
  //           } else if (value>50 && value<=100) {
  //             return '100\n 良';
  //           } else if (value>100 && value<=150) {
  //             return '150\n轻度';
  //           } else if (value>150  && value<=200) {
  //             return '200\n中度';
  //           } else if (value>200  && value<=299) {
  //             return '300\n重度';
  //           } else if (value>=300) {
  //             return '500\n严重';
  //           }
  //         }
  //       },
  //       title: {
  //         offsetCenter: ['0%', '20%'],
  //         fontSize: 16,
  //         textStyle:{
  //           color:"#fff"
  //         }
  //       },
  //       detail: {
  //         offsetCenter: ['0%', '-20%'],
  //         fontSize: 26,
  //         fontWeight: 100,
  //         color: '#fff',
  //         formatter: '{value}'
  //       }
  //     }
  //   ]
  // };
  // echar.setOption(option);
  var deg;
  if(d<=200){
    deg = d/50*(360/7);
  }else if(d<=300){
    deg = 360/7*4 + (d-200)/100*(360/7);
  }else if(d<=500){
    deg = 360/7*5 + (d-300)/200*(360/7);
  }

  var x=150;
  var y=170;
  var r=120;

  //画一个大圆分成7分
  oGC.beginPath();
  oGC.strokeStyle = 'rgba(47,71,105, .8)';
  oGC.lineWidth = 2;
  oGC.arc(x,y,r,(90+360/14+0*360/7)*Math.PI/180,(90+360/14+(5+1)*360/7)*Math.PI/180,false);
  oGC.stroke();


  //绘制刻度线
//			oGC.beginPath();
  for(var i=0;i<8;i++) {
    drawTick((90 + 360 / 14 + i * 360 / 7) * Math.PI / 180,r,25)
//				oGC.arc(x, y, r, (90 + 360 / 14 + i * 360 / 7) * Math.PI / 180, (90 + 360 / 14 + (i + 1) * 360 / 7) * Math.PI / 180, false);
  }
//			oGC.stroke();

  function drawTick(angle, radius, cnt) {
    var tickWidth = 10;

    oGC.beginPath();

    oGC.moveTo(x + Math.cos(angle) * (radius - tickWidth),
        y + Math.sin(angle) * (radius - tickWidth));

    oGC.lineTo(x + Math.cos(angle) * (radius),
        y + Math.sin(angle) * (radius));

    oGC.strokeStyle = "rgba(47,71,105, .8)";
    oGC.stroke();
  }





  var n = Math.ceil(deg/(360/7));
  var color = ["#73B962","#C5B14F","#D4794A"];
  for(var i=0;i<n;i++) {
    oGC.beginPath();
    oGC.strokeStyle = color[i];
    oGC.fillStyle="transparent";
    oGC.lineWidth = 5;
    if(i==n-1){
      oGC.arc(x, y, r - 3, (90 + 360 / 14 + i * 360 / 7) * Math.PI / 180, (90 + 360 / 14 + deg) * Math.PI / 180, false);
    }else {
      oGC.arc(x, y, r - 3, (90 + 360 / 14 + i * 360 / 7) * Math.PI / 180, (90 + 360 / 14 + (i + 1) * 360 / 7) * Math.PI / 180, false);
    }
    oGC.fill();
    oGC.stroke();
  }

}